<template>
  <b-modal id="modal-customer" size="lg" title="Detail Customer">
    <div class="mb-2">
      <label>Tipe Pelanggan</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.customer_type || '-' }}
      </b-col>
    </div>

    <div class="mb-2">
      <label>Email</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.email || '-' }}
      </b-col>
    </div>

    <b-row class="m-0">
      <b-col cols="6" class="p-0">
        <div class="mb-2">
          <label>Nama</label>
          <b-col cols="12" class="custom__input mb-2 pt-1">
            {{ detailResult.name || '-' }}
          </b-col>
        </div>
      </b-col>
      <b-col cols="6" class="p-0">
        <div class="mb-2 ml-2">
          <label>No. Telepon</label>
          <b-col cols="12" class="custom__input mb-2 pt-1">
            {{ detailResult.phone_number || '-' }}
          </b-col>
        </div>
      </b-col>
    </b-row>

    <b-row class="m-0">
      <b-col cols="6" class="p-0">
        <div class="mb-2">
          <label>Nama Alias</label>
          <b-col cols="12" class="custom__input mb-2 pt-1">
            {{ detailResult.alias_name || '-' }}
          </b-col>
        </div>
      </b-col>
      <b-col cols="6" class="p-0">
        <div class="mb-2 ml-2">
          <label>Nama Kontak</label>
          <b-col cols="12" class="custom__input mb-2 pt-1">
            {{ detailResult.contact_name || '-' }}
          </b-col>
        </div>
      </b-col>
    </b-row>

    <div class="mb-2">
      <label>Alamat</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.address || '-' }}
      </b-col>
    </div>

    <div class="mb-2">
      <label>Terms of Payment</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.terms_of_payment || '-' }}
      </b-col>
    </div>

    <div class="mb-2">
      <label>Nomor NPWP</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.tax_id_number || '-' }}
      </b-col>
    </div>

    <div class="mb-2">
      <label>Lampiran Foto NPWP</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.tax_id_photo_url || '-' }}
      </b-col>
    </div>

    <div class="mb-2">
      <label>Nomor KTP</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.id_card_number || '-' }}
      </b-col>
    </div>

    <div class="mb-2">
      <label>Lampiran Foto KTP</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ detailResult.id_card_photo_url || '-' }}
      </b-col>
    </div>
    <template #modal-footer="{}">
      <b-button variant="primary" @click="$bvModal.hide('modal-customer')">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'

import moment from 'moment'
import BaseInput from '@/components/BaseInput.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
  },
  props: {
    getData: {
      type: Function
    },
    getDetail: {
      type: Function
    },
    result: {
      type: Object
    },
    detailResult: {
      type: Object
    },
  },
  methods: {
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    directPage() {
      //   this.cleanUpForm()
      this.$bvModal.hide('modal-customer')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>