<template>
    <div class="">
        <b-row class="my-2 align-items-center">
            <b-col cols="12" lg="10">
            </b-col>
            <b-col cols="12" lg="2" class="">
                <div>
                    <b-button variant="primary" @click="exportReport">
                        Export Data
                    </b-button>
                </div>
            </b-col>
        </b-row>

        <b-card-code no-body>
            <div class="table-responsive-sm" style="overflow-x: auto">
                <table class="table b-table table-fixed">
                    <thead>
                        <tr>
                            <th v-for="column, index in columns" :key="index">{{ column.label }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, index in result.data" :key="`table-${index}`">
                            <td>{{ item.name }}</td>
                            <td>{{ item.merchant && item.merchant.name }}</td>
                            <td class="text-right">
                                <div class="btn-group" role="group" aria-label="Basic example">

                                    <b-button variant="primary" size="sm" @click="goToDetail(item.uuid)">
                                        Lihat Detail
                                    </b-button>

                                </div>
                            </td>
                        </tr>
                        <tr v-if="result.total == 0">
                            <td colspan="5" class="text-center">
                                Data is empty.
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="row mt-1">
                    <div class="col-6 pl-2">
                        <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
                    </div>
                    <div class="col-6 pr-2">
                        <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
                    </div>
                </div>
            </div>
        </b-card-code>
    </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BRow, BCol, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

import moment from 'moment'
import FileSaver from 'file-saver'

export default {
    components: {
        BCardCode,
        VueGoodTable,
        BFormSelect,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BButton,
        BRow,
        BCol,
    },
    directives: {
        'b-toggle': VBToggle,
    },
    props: {
        getData: {
            type: Function
        },
        getDetail: {
            type: Function
        },
        result: {
            type: Object
        },
        detailResult: {
            type: Object
        },
    },
    data() {
        return {
            progressValue: 35,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Nama Customer',
                    field: 'name',
                },
                {
                    label: 'Nama Toko',
                    field: 'merchant.name',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            items: [],
            search: '',
            filter: {
                search: '',
                sort_type: 'desc',
                per_page: 10,
            },
        }
    },
    watch: {
        filter: {
            handler(value) {
                this.$emit('filterBranch', value)
            },
            deep: true,
        },
    },
    methods: {
        goToDetail(uuid) {
            this.getDetail(uuid)
            this.$bvModal.show('modal-customer')
        },
        async exportReport() {
            // const queryParams = filter

            const response = await this.$http.get('/super-admin/customer/export', {
                responseType: 'blob',
                // params: queryParams,
            })

            const dateDownload = new Date()

            const timestamp = moment(dateDownload).format('YYYYMMDD')
            let fileName = ''

            fileName = `daftar-pelanggan-${timestamp}.xlsx`
            FileSaver.saveAs(response.data, fileName)
            // console.log(response, ' report')
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.section__customer {
    .vgt-responsive {
        height: calc(100vh - 235px) !important;
        background-color: #fff;
    }
}
</style>